import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import { PagesService} from '../../../pages/pages.service';
import { SharedService } from 'src/app/shared/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from 'src/app/shared/translation.service';

@Component({
  selector: 'app-profile-change-password',
  templateUrl: './profile-change-password.component.html',
  styleUrls: ['./profile-change-password.component.scss']
})
export class ProfileChangePasswordComponent implements OnInit {
//key for disable button
key:boolean=false;

// to make button diabled untill have all data
  btnDisabled:any = "true";

  //data of user
  data:any;

  //current password
  currentPassword:string = "";

  //new password
  newPassword:string = "";

  //confirm password
  confirmPassword:string = "";

  //mesg from response
  mesg:any;

  constructor(
    private pagesService: PagesService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    public sharedService: SharedService,
    public translate: TranslationService,
  ) {
  }

  ngOnInit(): void {
      this.spinner.hide();
    console.log("cu",this.currentPassword);


  }

 onchangeInputs=()=>{
  //  if(this.currentPassword.length > 5 && this.currentPassword.length !== undefined){
  //    console.log("enter1");

  //   if(this.newPassword.length > 5 && this.newPassword.length !== undefined){
  //     console.log("enter2");
  //     if(this.newPassword === this.confirmPassword){
  //       console.log("enter3",this.newPassword);

  //       this.btnDisabled = "false";
  //       console.log(this.btnDisabled);

  //     }
  //   }
  //  }
 }

  handlePasswordChange=()=>{
    this.key = true;

    console.log("hh",this.currentPassword);
    if(this.currentPassword.length == 0 || this.currentPassword.length == undefined){

    }else{
      this.data = {
        oldPassword: this.currentPassword,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword
      }

      console.log("this.data",this.data);



       this.pagesService.changePassword(this.data).subscribe(
        (response: any) => {
          this.mesg = response.data;
          console.log("password change",this.mesg);
          this.key= false;
        },
        (error:any) => {
          console.log("error",error);
          this.key= false;
        }
      )
      // this.reloadComponent();
    }

  }



  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
  }

}
