import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { ProfileChangePasswordRoutingModule } from './profile-change-password-routing.module';
import { ProfileChangePasswordComponent } from './profile-change-password.component';
import { PagesSharedModule } from '../../pagesShared.module';


@NgModule({
  declarations: [ProfileChangePasswordComponent],
  imports: [
    CommonModule,
    ProfileChangePasswordRoutingModule,
    PagesSharedModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ProfileChangePasswordModule { }
