  <div class="changePassword">
    <div class="row">
      <div class="col-12 customOverflow">
        <div class="row">
          <div class="col-12">
            <div [ngClass]="{'header': translate.is_arabic, 'headerEn': !translate.is_arabic}">
              <p>{{translate.data.profile.account}}</p>
            </div>
            <div [ngClass]="{'body': translate.is_arabic, 'bodyEn': !translate.is_arabic}">
              <div class="container">
                <form>
                  <div>
                    <label for="">{{translate.data.passwordChange.currentPassword}}</label>
                    <input type="password"
                          [(ngModel)]="currentPassword"
                          [ngModelOptions]="{standalone: true}" (change)="onchangeInputs()">
                    <!-- <p *ngIf="currentPassword.length < 5" class="text-danger">يجب ان تكون اكثر من 5</p> -->
                  </div>
                  <div>
                    <label for="">{{translate.data.passwordChange.newPassword}}</label>
                    <input type="password"
                          [(ngModel)]="newPassword"
                          [ngModelOptions]="{standalone: true}" (change)="onchangeInputs()">
                          <!-- <p *ngIf="newPassword.length < 5" class="text-danger">يجب ان تكون اكثر من 5</p> -->
                  </div>
                  <div>
                    <label for="">{{translate.data.passwordChange.conformPassword}}</label>
                    <input type="password"
                          [(ngModel)]="confirmPassword"
                          [ngModelOptions]="{standalone: true}" (change)="onchangeInputs()">
                    <!-- <p *ngIf=" newPassword !== confirmPassword" class="text-danger">مش نفس كلمة المرور</p> -->
                  </div>
                  <div class="button-container">
                    <button [disabled]="key"  (click)="handlePasswordChange()">{{translate.data.profile.update}}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<router-outlet></router-outlet>

